















import { Component } from 'vue-property-decorator'
import Vue from 'vue'
import dashboardIcon from '~/assets/dashboard-icon.png'

@Component({
  name: 'view-page-not-found',
})
export default class PageNotFound extends Vue {
  public dashboardIcon = dashboardIcon
  public backToHome() {
    this.$router.push({ name: 'Dashboard' })
  }
}










import { Component } from 'vue-property-decorator'
import Vue from 'vue'

import DashboardContent from '~/components/dashboard/Content.vue'
import DashboardHeader from '~/components/dashboard/Header.vue'

@Component({
  components: {
    DashboardContent,
    DashboardHeader,
  },
  name: 'view-dashboard',
})
export default class Dashboard extends Vue {}






























import { Component, Watch } from 'vue-property-decorator'
import Vue from 'vue'

import { AtGroupMenu, AtMyApps, AtMyAvatar } from '@technology/accounts-vue-sdk'
import { GroupModel } from '@technology/accounts-js/dist/types/modules/group'

import DrawerMenu from '~/components/common/DrawerMenu.vue'
import config from '~/config'
import icon from '~/assets/dashboard-icon.png'

@Component({
  components: {
    AtGroupMenu,
    AtMyApps,
    AtMyAvatar,
    DrawerMenu,
  },
  name: 'view-root',
})
export default class Root extends Vue {
  public drawer: boolean = false
  public header: any = {
    basic: {
      icon,
      name: 'Dashboard',
    },
  }
  public loading: boolean = false
  public signInPath: string = config.signInPath
  // computed
  get currentAccount() {
    return this.$store.myGetters['accounts/account/currentAccount']
  }

  get groups() {
    return this.$store.myGetters['accounts/group/all']
  }

  get isMobile() {
    return this.$store.myGetters.getIsMobile
  }

  get signOutPath() {
    const query = encodeURIComponent(`${window.location.origin}${this.$route.fullPath}`)
    return `${config.signOutPath}?continue=${query}`
  }

  public changeGroup({ group }: { group: GroupModel }) {
    const groupData = this.$store.getters['accounts/group/getById'](group.id)
    this.$router.push({ params: { groupName: groupData.name } })
  }

  public async beforeMount() {
    await this.$store.myActions.fetchDashboardAppConfig()
  }

  @Watch('$route')
  public onRouteChange() {
    this.drawer = false
  }
}
